import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const marchesSlice = createSlice({
    name: 'marches',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsMarches: (state) => {
            if (state.detailsMarches == null) {
                state.detailsMarches = ''
            } else {
                state.detailsMarches = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsMarches.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsMarches.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsMarches = action.payload
            })
            .addCase(fetchDetailsMarches.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchMarches.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchMarches.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchMarches.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createMarche.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createMarche.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createMarche.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })




            .addCase(employesMarche.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(employesMarche.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(employesMarche.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })










            .addCase(deleteMarche.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteMarche.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteMarche.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchMarches = createAsyncThunk(
    'marches/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/marche.marche.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch marches')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsMarches = createAsyncThunk(
    'Marches/fetchDetails',
    async (id) => {
        const data = { 'id': id.id }
        try {
            const Marche = await BaseService.post(
                `/XML/marche.marche.load/get_one_marche`, data
            )
            const Client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`, { 'idcl': Marche.data?.marche?.[0]?.idcl }
            )
            const objet = { '0': 'Dépose', '1': "Valide", '2': "En cours de paiement ", '3': 'Payer' }
            if (Marche.data.marche[0] && typeof id.side !== 'undefined') {
                Marche.data.marche[0]['totalTtc'] = Marche.data.marche[0]['mt_total']
                Marche.data.marche[0]['statut'] = objet[Marche.data.marche[0]['statut']]
                Marche.data.marche[0]['relicat'] = formatToDH(Marche.data.marche[0]['relicat'])
                Marche.data.marche[0]['mt_total'] = formatToDH(Marche.data.marche[0]['mt_total'])
                Marche.data.marche[0]['estimation'] = formatToDH(Marche.data.marche[0]['estimation'])
                Marche.data.marche[0]['caution'] = formatToDH(Marche.data.marche[0]['caution'])

            }

            const obj = {}
            // console.log(marche);
            const decompte = Marche?.data?.sum_decompte
            const achat = Marche?.data?.achats
            decompte.forEach(element => {
                obj[element.idp] = { 'ref': element['référence'], 'type': element['type'], 'qte_decompte': element.qte, 'unité': element['unité'] }
            })
            achat.forEach(element => {
                if (typeof obj[element.idp] === 'undefined') {
                    obj[element.idp] = { 'ref': element['référence'], 'type': element['type'], 'qte_decompte': 0, 'unité': element['unité'] }
                }
                obj[element.idp].qte_achats = element['qte']

            })
            const recap = []
            Object.keys(obj).forEach(key => {
                recap.push(obj[key])
            })

            return { Marche: Marche.data, Client: Client.data, recap: recap }
        } catch (error) {
            throw new Error('Failed to fetch Marches')
        }
    }
)

export const createMarche = createAsyncThunk(
    'marches/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/marche.marche.send/ajouter', data)
            await BaseService.post('/XML/chantier.dossier.send/ajouter', { 'id_marche': response.data.id })
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)


export const employesMarche = createAsyncThunk(
    'employesMarche/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/marche.marche.send/employes', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            setSubmitting(false)
            popNotification(creation?.error)
        }
    }
)


export const editMarche = createAsyncThunk('marches/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/marche.marche.send/modifier', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})


export const commentMarche = createAsyncThunk('marches/comment', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/marche.marche.send/comment', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})








export const deleteMarche = createAsyncThunk(
    'marche/delete',
    async (payload) => {
        const { data, setMarcheAdded } = payload

        try {
            const response = await BaseService.post('/XML/marche.marche.send/delete', data)
            if (response.status === 200) {


                popNotification(creation?.success)
                setMarcheAdded(true)
            }
        } catch (err) {
            console.log({ err })



            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.marches.data
export const selectIsLoading = (state) => state.marches.isLoading
export const selectError = (state) => state.marches.error
export const selectIsDeleted = (state) => state.marches.isDeleted
export const selectCurrentPage = (state) => state.marches.currentPage
export const resetDetailsMarches = marchesSlice.actions.resetDetailsMarches
export default marchesSlice.reducer
