import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import isNumber from 'is-number'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const decomptesSlice = createSlice({
    name: 'decomptes',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsDecompte: (state) => {
            if (state.detailsDecompte == null) {
                state.detailsDecompte = ''
            } else {
                state.detailsDecompte = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsDecomptes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsDecomptes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsDecompte = action.payload
            })
            .addCase(fetchDetailsDecomptes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDecomptes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDecomptes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchDecomptes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createDecompte.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createDecompte.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createDecompte.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteDecompte.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteDecompte.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteDecompte.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchDecomptes = createAsyncThunk(
    'decomptes/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/chantier.decompte.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch decomptes')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsDecomptes = createAsyncThunk(
    'Decomptes/fetchDetails',
    async (id) => {
        const data = { 'id': id.id }
        try {
            const Decompte = await BaseService.post(
                `/XML/chantier.decompte.load/get_one_decompte`, data
            )
            const Client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`, { 'idcl': Decompte.data?.marche?.[0]?.idcl }
            )
            const idMarche = Decompte.data?.marche?.[0]?.id

            const detailsMarche = await BaseService.post(
                `/XML/marche.marche.load/get_one_marche`, { 'id': idMarche }
            )
            Decompte.data.recap.push({ 'nature': 'A Déduir', 'fait': '', 'garantie': '', 'taux_garantie': '', 'reste': Decompte.data.decompte[0]['deduir'] })
            Decompte.data.recap.push({ 'nature': 'Acompte À Delivrer', 'fait': '', 'garantie': '', 'taux_garantie': '', 'reste': Decompte.data.decompte[0]['mt_delivrer'] })


            return { decompte: Decompte.data, client: Client.data, detailsMarche: detailsMarche.data }

            return Decompte.data
        } catch (error) {
            throw new Error('Failed to fetch Decomptes')
        }
    }
)
export const createDecompte = createAsyncThunk(
    'decomptes/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/chantier.decompte.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Chantier/Decompte')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)




export const confDecompte = createAsyncThunk(
    'decomptes/conf',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        const id = data.id
        const numero = data.numero
        const date = data?.date || new Date()
        try {
            const decompte = (await BaseService.post('/XML/chantier.decompte.load/get_one_decompte', { 'id': id })).data

            ///////////ajouter la vente
            let n = 0
            const obj = {
                "idc": "",
                "num_bc": "",
                "idcl": decompte.marche[0]['idcl'],
                "date_livraison": formatDateSend(date),
                "local": "1",
                "conditions": "",
                'id_decompte': decompte.decompte[0]['id']

            }

            decompte.contenu.forEach(element => {
                obj['pr_' + n] = element['idp']
                obj['prix_' + n] = element['puht']
                obj['qte_' + n] = element['qte_fact']
                obj['taux_tva_' + n] = element['tva']
                n++
            })
            obj['n'] = n


            const vente = (await BaseService.post('/XML/ve.commande.send/NCommande', obj)).data
            const idVente = vente.id
            await BaseService.post('/XML/ve.commande.send/confirmer_ddp', { 'idc': idVente })
            const obgfact = {
                'date_reglement_p': formatDateSend(date), 'conditions': '', 'idc': idVente, 'decompte': '', 'numero': numero, 'date': formatDateSend(date)
            }

            ///////creation de la facture
            const numFact = (await BaseService.post('/XML/compta.factc.send/oneco', obgfact)).data
            //confirmation du decompte
            const response = await BaseService.post('/XML/chantier.decompte.send/conf', { 'id_fact': numFact.idfact, 'date': formatDateSend(date), 'id': id })


            if (response.status === 200) {
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editDecompte = createAsyncThunk(
    'decomptes/edit',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/chantier.decompte.send/edit', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Chantier/Decompte')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const deleteDecompte = createAsyncThunk(
    'decompte/delete',
    async (payload) => {
        const { data, setDecompteAdded } = payload
        try {
            const decompte = (await BaseService.post('/XML/chantier.decompte.load/get_one_decompte', data)).data
            //delete the facture
            const idFact = decompte.decompte[0]['id_fact']
            const deleteDecompte = await BaseService.post('XML/chantier.decompte.send/supprimer', data)

            if (deleteDecompte.status === 200) {
                if (isNumber(idFact)) {
                    const deleteFact = await BaseService.post('XML/compta.factc.send/supprimer', { 'id_fact': idFact })
                    if (deleteFact.status === 200) {
                        popNotification(creation?.success)
                        setDecompteAdded(true)
                    }
                } else {
                    popNotification(creation?.success)
                    setDecompteAdded(true)
                }



            }
        } catch (err) {
            console.log({ err })
            

            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.decomptes.data
export const selectIsLoading = (state) => state.decomptes.isLoading
export const selectError = (state) => state.decomptes.error
export const selectIsDeleted = (state) => state.decomptes.isDeleted
export const selectCurrentPage = (state) => state.decomptes.currentPage
export const resetDetailsDecompte = decomptesSlice.actions.resetDetailsDecompte
export const startLoading = decomptesSlice.actions.startLoading
export const stopLoading = decomptesSlice.actions.stopLoading
export default decomptesSlice.reducer
