import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import { updateKeys } from '../../functions/updateKeys'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const calendriersSlice = createSlice({
    name: 'calendriers',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCalendriers.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCalendriers.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchCalendriers.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
          
    },
})

// Async thunk action to fetch data from the API
export const fetchCalendriers = createAsyncThunk(
    'calendriers/fetch',
    async (payload) => {
       


        try {
            const response = await BaseService.post(
                `/XML/crm.pip.load/get_all_events`
            )
            const keyMap = {
                'date_f':'end',
                'date_d':'start',
                'evenement':'title'
                        }
               

            return updateKeys(response.data,keyMap)
        } catch (error) {
            throw new Error('Failed to fetch calendriers')
        }
    }
)



export const editCalendrier = createAsyncThunk('calendriers/edit', async (data) => {    
    try {
     
        const response = await BaseService.post(`/XML/crm.pip.send/update_event`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/calendriers')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const selectData = (state) => state.calendriers.data
export const selectIsLoading = (state) => state.calendriers.isLoading
export const selectError = (state) => state.calendriers.error
export const selectIsDeleted = (state) => state.calendriers.isDeleted
export const selectCurrentPage = (state) => state.calendriers.currentPage

export default calendriersSlice.reducer
