import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const nomenclaturesSlice = createSlice({
    name: 'nomenclatures',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsNomenclatures.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsNomenclatures.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.data = action.payload
        })
        .addCase(fetchDetailsNomenclatures.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchNomenclatures.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchNomenclatures.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchNomenclatures.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createNomenclature.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createNomenclature.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createNomenclature.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteNomenclature.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteNomenclature.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteNomenclature.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchNomenclatures = createAsyncThunk(
    'nomenclatures/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/pr.nomenclature.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch nomenclatures')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsNomenclatures = createAsyncThunk(
    'Nomenclatures/fetchDetails',
    async (id) => {
        const data = {'id':id.id}
        try {
            const Nomenclature = await BaseService.post(
                `/XML/pr.nomenclature.load/get_one`,data
            )
            
            const Article = await BaseService.post(
                `/XML/st.produits.load/get_one_product`,{'idp':Nomenclature.data.nomenclature[0]['idp_fini']}
            )

            const objet = {
                'M': 'Marchandise',
                'C': 'Consommable',
                'S': 'Autre',
                'MP':'Matière première',
                'PF':'Produit fini'
            }
            if (Article.data[0]) {
                Article.data[0]['catégorie'] = objet[Article.data[0]['catégorie']]
                Article.data[0]['CA'] = formatToDH(Article.data[0]['CA'])
                Article.data[0]['PA'] = formatToDH(Article.data[0]['PA'])
                Article.data[0]['PV'] = formatToDH(Article.data[0]['PV'])
            }
            const machine = []
            Nomenclature?.data?.hm?.map((item)=>{
                machine.push({ma:{label:item.nom,value:item.id_machine},hm:item.hm,cout_hm:item.cout_hm})
            
            })
            const matiere = []
            Nomenclature?.data?.contenu?.map((item)=>{
                matiere.push({pr:{label:item.référence,value:item.id},qte:item.qte})

            })

            const standard = []
            Nomenclature?.data?.standard?.map((item)=>{
                standard.push({id:{label:item.nom,value:item.id}})

            })
            
            return {'nomenclature':Nomenclature.data,'Article':Article.data,'machine':machine,'matiere':matiere,'standard':standard}
        } catch (error) {
            throw new Error('Failed to fetch Nomenclatures')
        }
    }
)
export const createNomenclature = createAsyncThunk(
    'nomenclatures/post',
    async (payload) => {

        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/pr.nomenclature.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Production/Nomenclatures')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editNomenclature = createAsyncThunk('nomenclatures/edit',  async (payload) => {

    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/pr.nomenclature.send/edit', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            navigate('/Production/Nomenclatures')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
})

export const deleteNomenclature = createAsyncThunk(
    'nomenclature/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/nomenclatures/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.nomenclatures.data
export const selectIsLoading = (state) => state.nomenclatures.isLoading
export const selectError = (state) => state.nomenclatures.error
export const selectIsDeleted = (state) => state.nomenclatures.isDeleted
export const selectCurrentPage = (state) => state.nomenclatures.currentPage

export default nomenclaturesSlice.reducer
