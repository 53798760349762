import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const facturesFournisseursSlice = createSlice({
    name: 'facturesFournisseurs',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsFacturesFournisseur: (state) => {

            if (state.detailsFacturesFournisseur == null) {
                state.detailsFacturesFournisseur = ''
            } else {
                state.detailsFacturesFournisseur = null
            }
        },
        resetDataCreateFacturesFournisseurs: (state) => {

            if (state.dataAdd == null) {
                state.dataAdd = ''
            } else {
                state.dataAdd = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsFacturesFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsFacturesFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsFacturesFournisseur = action.payload
            })
            .addCase(fetchDetailsFacturesFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchFacturesFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchFacturesFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchFacturesFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(fetchDataCreateFacturesFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDataCreateFacturesFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.dataAdd = action.payload
            })
            .addCase(fetchDataCreateFacturesFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })







            .addCase(createFacturesFournisseur.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createFacturesFournisseur.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createFacturesFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteFacturesFournisseur.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteFacturesFournisseur.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteFacturesFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchFacturesFournisseurs = createAsyncThunk(
    'facturesFournisseurs/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/compta.factf.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch facturesFournisseurs')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsFacturesFournisseurs = createAsyncThunk(
    'FacturesFournisseurs/fetchDetails',
    async (id) => {



        try {
            const FacturesFournisseur = await BaseService.post(
                `/XML/compta.factf.load/side_details_fact`, { 'idf': id.id }
            )
            const content = await BaseService.post(
                `/XML/compta.factf.load/calc_fact_by_id`, { 'idf': id.id }
            )
            const journal = await BaseService.post(
                `/XML/compta.jr.load/get_journal_by_lbl`, { 'lbl': 'FAC_D_ACH_' + id.id }
            )
            const Fournisseur = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`, { 'idfr': FacturesFournisseur.data.fact[0].idfr }
            )
            const avoirs = await BaseService.post(
                `/XML/compta.avoir_f.load/get_avoir_one_fact`, { 'id_fact': id.id }
            )

            const reglement = (await BaseService.post(`/XML/compta.factc.load/get_reg_fact_c`, { 'lbl_int': 'REG_FAC_D_ACH_' + id.id })).data;

            const typesRegelement = [];
            const obj_pay = {
                'v': 'Virement',
                'esp': 'Espèce',
                'c': 'Chèque'
            };

            await Promise.all(reglement.map(async (element, index) => {
                const piece = element['lbl_piece'].split('__')[0];
                const id = element['lbl_piece'].split('__')[1];
                if (!isNaN(id)) {
                    if (piece.startsWith('banque') || piece.startsWith('caisse')) {
                        const tr = (await BaseService.post('/XML/tr.ba.load/get_transaction_by_id', { 'compte': piece, 'id': id }))?.data?.[0];
                        typesRegelement.push({ type: obj_pay[tr['nature'].toLowerCase()], MT: tr['MT'], ref: tr['ref'], date: tr['dateo'] });
                    } else if (piece.startsWith('cheque')) {
                        const tr = (await BaseService.post('/XML/tr.cheque.load/get_one_cheque', 'idc=' + id))?.data?.[0];
                        typesRegelement.push({ type: "Cheque", MT: tr['MT'], ref: tr['ref'], date: tr['datec'] });
                    } else if (piece.startsWith('chf')) {
                        const tr = (await BaseService.get(`/XML/tr.chequeFr.load/details?id=${id}`))?.data?.data
                        typesRegelement.push({ type: "Cheque", MT: tr['MT'], ref: tr['ref'], date: tr['date'] });
                    } else if (piece.startsWith('ec')) {
                        let t = 'c';
                        if (piece.split('_')[1] == 'c') {
                            t = 'd';
                        }
                        const tr = (await BaseService.post('/XML/tr.ec.load/get_one_ec', 'type_ec=' + t + '&idec=' + id))?.data?.[0];
                        typesRegelement.push({ type: "Effet de commerce", MT: tr['MT'], ref: tr['ref'], date: tr['date'] });
                    } else if (piece.startsWith('Avoir')) {
                        const MT = reglement[index]['MT'];
                        typesRegelement.push({ type: "Avoir", MT: MT, ref: reglement[index]['ref'], date: reglement[index]['date'] });
                    }
                }
            }));





            return { 'facture': FacturesFournisseur.data, 'content': content.data, 'journal': journal.data, 'Fournisseur': Fournisseur.data, 'avoirs': avoirs.data, 'typesRegelement': typesRegelement }

        } catch (error) {
            throw new Error('Failed to fetch FacturesFournisseurs')
        }
    }
)


export const fetchDataCreateFacturesFournisseurs = createAsyncThunk(
    'FacturesFournisseurs/fetchDetailsAdd',
    async ({ idfr, type, idCo }) => {



        try {
            const fournisseur = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`, { 'idfr': idfr }
            )
            let commande = await BaseService.post(
                `/XML/ap.co.load/get_one_commande`, { 'idc': idCo }
            )
            return { 'fournisseur': fournisseur.data, 'commande': commande.data }

        } catch (error) {
            throw new Error('Failed to fetch FacturesFournisseurs')
        }
    }
)

export const createFacturesFournisseur = createAsyncThunk(
    'facturesFournisseurs/post',
    async (payload) => {
        const { data, formik, navigate, urlOrigine } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/compta.factf.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)
export const createAvoirsFournisseur = createAsyncThunk(
    'avoirsFournisseurs/post',
    async (payload) => {
        const { data, formik, navigate, } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/compta.avoir_f.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)

                navigate('/comptabilite/FacturesFournisseurs')

            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)








export const PayFacturesFournisseur = createAsyncThunk(
    'facturesFournisseurs/pay',
    async (payload) => {
        const { data, formik, navigate, urlOrigine } = payload
        const { resetForm, setSubmitting } = formik
        try {
            for (let i = 0; i < data?.transactions?.length; i++) {
                const element = data?.transactions[i];
                const type = element?.type
                const compte = element?.compte.split('_')[1]
                const mt = element?.MT
                const ref = element?.ref
                const idfact = data?.idfact
                const echeance = element?.date
                const date_valeur = element?.date_valeur
                const idavoir = element?.idavoir
                if (data?.typePay == 'newTr') {

                    if (type == 'esp' || type == 'vr' || type == 'ch' || type == "ver" || type == "p") {
                        await BaseService.post('/XML/tr.ba.send/pay_fact_f', { type: type, compte: compte, mt: mt, ref: ref, idfact: idfact, date_valeur: date_valeur })
                    } else if (type == 'ec') {
                        await BaseService.post('/XML/tr.ec.send/pay_fact_f', { type: type, compte: compte, mt: mt, ref: ref, idfact: idfact, echeance: echeance, date_valeur: date_valeur })
                    } else if (type == 'chd') {
                        await BaseService.post('/XML/tr.chequeFr.send/pay_fact_f', { type: type, compte: compte, mt: mt, ref: ref, idfact: idfact, date_valeur: date_valeur, echeance: echeance })
                    }

                } else if (data?.typePay == 'tr') {
                    await BaseService.post('/XML/tr.ba.send/lettre_fact_f', { ref: ref, idfact: idfact })
                } else if (data?.typePay == "av") {
                    await BaseService.post('/XML/compta.avoir_f.send/pay_fact_f', { id_fact: idfact, id_avoir: idavoir })
                } else if (data?.typePay == "ch") {
                    await BaseService.post('/XML/tr.chequeFr.send/lettre_fact_f', { cheque: ref, idfact: idfact })
                }else if (data?.typePay == "ec") {
                    await BaseService.post('/XML/tr.ec.send/lettre_fact', { ec: ref, idfact: idfact,type:'f' })
                }

            }


            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            navigate(-1)

        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)
export const editFacturesFournisseur = createAsyncThunk('facturesFournisseurs/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/compta.fact_f.send/modifier`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(edit?.error)
    }
})

export const deleteFacturesFournisseur = createAsyncThunk(
    'facturesFournisseur/delete',
    async (payload) => {
        const { data, setFacturesFournisseurAdded } = payload

        try {
            const response = await BaseService.post('/XML/compta.fact_f.send/supprimer', data)
            if (response.status === 200) {


                popNotification(creation?.success)
                setFacturesFournisseurAdded(true)
            }
        } catch (err) {
            console.log({ err })



            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.facturesFournisseurs.data
export const selectIsLoading = (state) => state.facturesFournisseurs.isLoading
export const selectError = (state) => state.facturesFournisseurs.error
export const selectIsDeleted = (state) => state.facturesFournisseurs.isDeleted
export const selectCurrentPage = (state) => state.facturesFournisseurs.currentPage
export const resetDetailsFacturesFournisseur = facturesFournisseursSlice.actions.resetDetailsFacturesFournisseur
export const resetDataCreateFacturesFournisseurs = facturesFournisseursSlice.actions.resetDataCreateFacturesFournisseurs
export const startLoading = facturesFournisseursSlice.actions.startLoading
export const stopLoading = facturesFournisseursSlice.actions.stopLoading
export default facturesFournisseursSlice.reducer
