import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const articlesWebsiteSlice = createSlice({
    name: 'articlesWebsite',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {

        resetDetailsArticles: (state) => {
            if (state.detailsArticles == null) {
                state.detailsArticles = ''
            } else {
                state.detailsArticles = null
            }
        },
        startLoading: state => {
            state.isLoading = true;
        },
        stopLoading: state => {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchArticles.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchArticles.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchArticles.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(fetchDetailsArticles.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsArticles.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsArticles = action.payload
            })
            .addCase(fetchDetailsArticles.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(createArticle.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createArticle.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })

            .addCase(editArticle.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(editArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(editArticle.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            



    },
})

// Async thunk action to fetch data from the API
export const fetchArticles = createAsyncThunk(
    'articles/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        const stockable = payload?.stockable || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/website.articles.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}&stockable=${stockable}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch articles')
        }
    }
)


export const createArticle = createAsyncThunk(
    'articles/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/website.articles.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsArticles = createAsyncThunk(
    'Articles/fetchDetails',
    async (id) => {
        try {
            const Article = (await BaseService.get(
                `/XML/website.articles.load/details?id=${id}`,
            )).data

            return { article: Article.article }
        } catch (error) {
            throw new Error('Failed to fetch Articles')
        }
    }
)

export const editArticle = createAsyncThunk('articles/edit',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/website.articles.send/edit', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const selectData = (state) => state.articlesWebsite.data
export const selectIsLoading = (state) => state.articlesWebsite.isLoading
export const selectError = (state) => state.articlesWebsite.error
export const selectIsDeleted = (state) => state.articlesWebsite.isDeleted
export const selectCurrentPage = (state) => state.articlesWebsite.currentPage
export const { resetDetailsArticles, startLoading, stopLoading } = articlesWebsiteSlice.actions
export default articlesWebsiteSlice.reducer
