import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const globalesSlice = createSlice({
    name: 'globales',
    initialState: {
        data: [],
        detailsGlobales: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsGlobales: (state) => {
            if (state.detailsGlobales == null) {
                state.detailsGlobales = ''
            } else {
                state.detailsGlobales = null
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGlobales.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchGlobales.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchGlobales.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDetailsGlobales.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsGlobales.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsGlobales = action.payload
            })
            .addCase(fetchDetailsGlobales.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(createGlobale.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createGlobale.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
                state.idDocument = action.payload
            })
            .addCase(createGlobale.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })




            .addCase(downloadGlobale.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(downloadGlobale.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(downloadGlobale.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
    },
})


// Async thunk action to fetch details from the API
export const fetchGlobales = createAsyncThunk(
    'Globales/fetch',
    async (id) => {
        const data = { 'idfr': id.id }
        try {
            const Globale = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`, data
            )
            const contact = await BaseService.post(
                `/XML/ap.fr.load/get_contact`, data
            )

            return { Globale: Globale.data, contact: contact.data }
        } catch (error) {
            throw new Error('Failed to fetch Globales')
        }
    }
)

export const fetchDetailsGlobales = createAsyncThunk(
    'Globales/fetchDetails',
    async (id) => {
        const data = { 'lbl': id.type, 'idlbl': id.id }


        try {
            const Globale = await BaseService.post(
                `/XML/docu.load/get_all_docu_lbl`, data
            )

            return Globale.data
        } catch (error) {
            throw new Error('Failed to fetch Globales')
        }
    }
)




export const createGlobale = createAsyncThunk(
    'globales/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded, idDocu, setIdDoc, index,idDoc } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/docu.send/ajouter`, data)
            if (idDocu) {

                await BaseService.post(`/XML/chantier.dossier.send/update_contenu`, { 'id_docu': response.data.id, 'id': idDocu.id })
                
                const updatedItems = [...idDoc];
                updatedItems[index] = response.data.id;
                setIdDoc(updatedItems)
            }
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
            return response.data
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)




export const downloadGlobale = createAsyncThunk(
    'globales/download',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const fileName = await BaseService.get(`/XML/docu.load/fileName?id=${data?.id}`)
            const response = await BaseService.post(`/XML/docu.load/download`, data, {
                responseType: 'blob',
            })
            if (response.status === 200) {
              
                let filename = fileName?.data || 'file'
              
                
                const file = new Blob(
                    [response.data],
                    { type: 'application/octet-stream' } // or the actual MIME type of the file
                );
                const fileURL = URL.createObjectURL(file);
                const downloadLink = document.createElement('a');
                downloadLink.href = fileURL;
                downloadLink.setAttribute('download', filename); // Replace with the actual filename and extension
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                resetForm()
                setSubmitting(false)
                setClientAdded(true)

            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.errorDownload)
        }
    }
)


export const selectData = (state) => state.globales.data
export const selectIsLoading = (state) => state.globales.isLoading
export const selectError = (state) => state.globales.error
export const selectIsDeleted = (state) => state.globales.isDeleted
export const selectCurrentPage = (state) => state.globales.currentPage
export const resetDetailsGlobales = globalesSlice.actions.resetDetailsGlobales
export default globalesSlice.reducer
