import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const ordresProductionsSlice = createSlice({
    name: 'ordresProductions',
    initialState: {
        data: [],
        detailsOrdreProduction: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsOrdresProductions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsOrdresProductions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsOrdreProduction = action.payload
            })
            .addCase(fetchDetailsOrdresProductions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchOrdresProductions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchOrdresProductions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchOrdresProductions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createOrdresProduction.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createOrdresProduction.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createOrdresProduction.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteOrdresProduction.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteOrdresProduction.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteOrdresProduction.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})
// Async thunk action to fetch details from the API
export const fetchDetailsOrdresProductions = createAsyncThunk(
    'OrdresProductions/fetchDetails',
    async (id) => {
        const data = { 'id': id.id }

        try {
            const OrdresProduction = await BaseService.post(
                `/XML/pr.ordre_production.load/get_one_ordre_production`, data
            )

            const Article = await BaseService.post(
                `/XML/st.produits.load/get_one_product_view`, { 'idp': OrdresProduction.data.ordre[0]['idp_fini'] }
            )
            const objet = {
                'M': 'Marchandise',
                'C': 'Consommable',
                'S': 'Autre',
                'MP': 'Matière première',
                'PF': 'Produit fini'
            }
            if (Article.data[0]) {
                Article.data[0]['catégorie'] = objet[Article.data[0]['catégorie']]
                Article.data[0]['CA'] = formatToDH(Article.data[0]['CA'])
                Article.data[0]['PA'] = formatToDH(Article.data[0]['PA'])
                Article.data[0]['PV'] = formatToDH(Article.data[0]['PV'])
            }


            return { 'ordre': OrdresProduction.data, 'Article': Article.data }
        } catch (error) {
            throw new Error('Failed to fetch OrdresProductions')
        }
    }
)
// Async thunk action to fetch data from the API
export const fetchOrdresProductions = createAsyncThunk(
    'ordresProductions/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/pr.ordre_production.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch ordresProductions')
        }
    }
)


export const confOrdresProduction = createAsyncThunk('ordresProductions/conf',

    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/pr.ordre.send/fin', data)
            if (response.status === 200) {
                const op = await BaseService.post('/XML/pr.ordre_production.load/get_one_ordre_production', { 'id': data.id })
                
                const objReception = {
                    'idop': op.data.ordre[0]['id'],
                    'idl': op.data.ordre[0]['id_local'],
                    'idp_0': op.data.ordre[0]['idp_fini'],
                    'qte_0': op.data.ordre[0]['qte_p_fini'],
                    'n': 1,
                    'date': op.data.ordre[0]['fin'],
                }

                const reception = await BaseService.post('/XML/st.reception.send/OP', objReception)
                if (reception.status === 200) {
                    resetForm()
                    setSubmitting(false)
                    popNotification(creation?.success)
                    setClientAdded(true)
                }
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }


)











export const createOrdresProduction = createAsyncThunk(
    'ordresProductions/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/pr.ordre.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Production/OrdresProductions')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editOrdresProduction = createAsyncThunk('ordresProductions/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/ordresProductions/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/ordresProductions')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteOrdresProduction = createAsyncThunk(
    'ordresProduction/delete',
    async (payload) => {
        const { data, setOrdresProductionAdded } = payload

        try {
            const response = await BaseService.post('/XML/pr.ordre.send/delete', data)
            if (response.status === 200) {
                popNotification(creation?.success)
                setOrdresProductionAdded(true)
            }
        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.ordresProductions.data
export const selectIsLoading = (state) => state.ordresProductions.isLoading
export const selectError = (state) => state.ordresProductions.error
export const selectIsDeleted = (state) => state.ordresProductions.isDeleted
export const selectCurrentPage = (state) => state.ordresProductions.currentPage

export default ordresProductionsSlice.reducer
